import React, { useState } from 'react';
import { createWorker } from 'tesseract.js';
import { Button, Card, CircularProgress, createStyles, Grid, makeStyles, TextField, Tooltip, Typography, useMediaQuery, } from "@material-ui/core";
import { log } from 'console';
import { summarizeText } from '../modules/summarizer';
import ProjectInfoModal from '../components/ProjectInfoModal'; // Import the modal component
import Markdown from 'markdown-to-jsx';

const FilePasteInput: React.FC = () => {
    const [files, setFiles] = useState<File[]>([]);
    const [extractedText, setExtractedText] = useState<String[]>([]);
    const [loading, setLoading] = useState<boolean>(false)
    const [summarizedText, setSummarizedText] = useState<string>('');
    const [showExtracted, setShowExtracted] = useState<boolean>(false);
    const [summarizing, setSummarizing] = useState<boolean>(false);
    const [sumarizeButton, setSummarizeButton] = useState<boolean>(false);
    const [modalOpen, setModalOpen] = useState<boolean>(false);

    // Styling
    const isLargeScreen = useMediaQuery("(min-width:1280px)");
    const isMediumScreen = useMediaQuery("(max-width:1280px)");
    const isSmallScreen = useMediaQuery("(max-width:600px)");

    let mainDiv = {};
    let inputBox = {};
    let button = {}
    if (isLargeScreen) {
        mainDiv = {
            width: '100%',
            textAlign: 'left',
            marginLeft: '1em',
            marginRight: '1em',
            minWidth: '300px'
        };
        inputBox = {
            marginBottom: '2em',
            marginTop: '1em',
            width: '100%'
        };
        button = {
            marginTop: '2em',
            width: '45%',
            marginLeft: 'auto',
            marginRight: 'auto',
            minWidth: '15em',
            maxWidth: '210px'
        }
    }
    if (isMediumScreen) {
        mainDiv = {
            textAlign: 'left',
            width: '100%',
            marginLeft: '1em',
            marginRight: '1em',
            minWidth: '300px'
        };
        inputBox = {
            marginBottom: '2em',
            marginTop: '1em',
            width: '100%'
        };
        button = {
            marginTop: '2em',
            width: '45%',
            marginLeft: 'auto',
            marginRight: 'auto',
            minWidth: '15em',
            maxWidth: '210px'
        }
    }
    if (isSmallScreen) {
        mainDiv = {
            textAlign: 'center',
            width: '100%',
            minWidth: '300px'
        };
        inputBox = {
            marginBottom: '2em',
            marginTop: '1em',
            width: '100%'
        };
        button = {
            marginTop: '2em',
            width: '45%',
            marginLeft: 'auto',
            marginRight: 'auto',
            minWidth: '15em',
            maxWidth: '210px'
        }
    }

    const useStyles = makeStyles(() =>
        createStyles({
            mainDiv: mainDiv,
            inputBox: inputBox,
            button: button
        })
    );
    const classes = useStyles();
    // End Styling
    const worker = createWorker({
        logger: m => console.log(m) // Add logger here to view the progress
    });

    // Handle Paste
    const handlePaste = async (event: React.ClipboardEvent) => {
        event.preventDefault();
        if (event.clipboardData && event.clipboardData.items) {
            const items = event.clipboardData.items;
            const newFiles: File[] = [];
            let containsFile = false;
            setLoading(true);

            for (let i = 0; i < items.length; i++) {
                if (items[i].kind === 'file') {
                    containsFile = true;
                    const file = items[i].getAsFile();
                    if (file) {
                        newFiles.push(file);
                        if (file.type.startsWith('image/')) {
                            await extractTextFromImage(file);
                            setLoading(false);
                            setShowExtracted(true);
                        }
                    }
                }
            }

            if (!containsFile) {
                setLoading(false);
                alert('Please paste an image or a file. Text or other data types are not supported.');
            }

            setFiles(currentFiles => [...currentFiles, ...newFiles]);
        }
    }

    // Extract Text 
    const extractTextFromImage = async (imageFile: File) => {
        try {
            await worker.load();
            await worker.loadLanguage('eng');
            await worker.initialize('eng');
            const { data: { text } } = await worker.recognize(imageFile);
            setExtractedText(existingText => [...existingText, text]);
            setSummarizeButton(true)
            await worker.terminate(); // Optional: terminate the worker if not needed anymore
        } catch (error) {
            console.error('Error extracting text: ', error);
            alert(`Error extracting text: ${error}`)
            await worker.terminate();
        }
    };

    // Clear 
    const clearFiles = () => {
        setFiles([]);
        setExtractedText([]);
        setShowExtracted(false);
        setSummarizedText('');
        setSummarizeButton(false)
    };
    // Summarizer 
    const handleSummarize = async () => {
        setSummarizing(true);
        try {
            const textToSummarize = extractedText.join(' ');
            const summary = await summarizeText(textToSummarize);
            setSummarizedText(summary);
        } catch (error) {
            console.error('Error summarizing text: ', error);
            alert(`Error summarizing text: ${error}`);
        }
        setSummarizing(false);
    };

    // Modal 
    const handleOpenModal = () => {
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
    };

    return (
        <React.Fragment>
            <Grid container spacing={1} direction="row" className={classes.mainDiv}>
                <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                    <h2>
                        Paste a screenshot or image in the input below to extract text from the image.
                    </h2>
                    {loading ?
                        <CircularProgress /> :
                        <TextField className={classes.inputBox} id="standard-basic" label="Screenshot or Image" variant="standard" onPaste={handlePaste} />
                    }
                    {!showExtracted ?
                        <></> :
                        <>
                            {extractedText.map((text, index) =>
                                <li key={index} >{text}</li>
                            )}
                        </>
                    }

                    {summarizeText && (
                        <>
                            {summarizing ? <CircularProgress /> :
                                <Typography >
                                    <Markdown>
                                        {summarizedText}
                                    </Markdown>
                                </Typography>}
                        </>
                    )}
                </Grid>
                <Grid item style={{ display: 'flex', flexDirection: 'column', marginTop: 'auto', marginBottom: 'auto' }} xs={12} sm={12} md={4} lg={4} xl={4}>
                    {showExtracted && (

                        <Tooltip title="Long list of extracted text may encounter error due to token limit">
                            <Button className={classes.button} variant="contained" color="primary" onClick={handleSummarize} disabled={summarizing}>
                                {summarizing ? 'Summarizing...' : 'Summarize'}
                            </Button>
                        </Tooltip>

                    )}
                    <Button className={classes.button} variant="contained" color="secondary" onClick={clearFiles}>
                        Clear
                    </Button>
                    <Button variant="contained" className={classes.button} onClick={handleOpenModal}>
                        About This Project
                    </Button>
                </Grid>
            </Grid>

            <ProjectInfoModal open={modalOpen} handleClose={handleCloseModal} />
        </React.Fragment>
    );
};

export default FilePasteInput;
