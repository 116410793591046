import React from "react";
import "../App.css";
import NavBar from "./NavBar";

function App() {
  return (
    <React.Fragment>
      <div style={{background: 'black',}} >
      <NavBar />
      </div>

    </React.Fragment>
  );
}

export default App;
