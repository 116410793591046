import AppBar from "@material-ui/core/AppBar";
import Grid from "@material-ui/core/Grid";
import idkNetworkLogo from "../resources/images/network.png";
import idkLogo from "../resources/images/idkBlackLogo.png";
import fiveLogo from "../resources/images/over500.png";
import twoHourLogo from "../resources/images/2HLB-full.png";
import { Link, BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import Landing from "../routes/Landing";


// Function for Navbar
export default function NavBar() {
  const useStyles = makeStyles(() =>
    createStyles({
      navBar: {
        backgroundColor: "#434654",
        flexGrow: 1,
      },
      networkImg: {
        height: "3em",
        marginLeft: "auto",
        marginRight: "auto",
        marginBottom: ".25em",
        marginTop: ".25em",
      },
      gridContainer: {
        marginTop:'1em',
        justifyContent: "center",
      },
      navGridItem: {},
    })
  );
  const classes = useStyles();
  return (
    <Router>
      <AppBar position="sticky" className={classes.navBar}>
      
  
        <Switch>
          <Route path="/">
            <Landing />
          </Route>
        </Switch>
      </AppBar>
    </Router>
  );
}
