import React from "react";
import "../App.css";
import idk from "../resources/images/idkLogoLarge.png";
import set from "../resources/images/set.png";
import tra from "../resources/images/traLogo.png";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Box, Grid, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";



// End of imorts
function Landing() {
  const useStyles = makeStyles(() =>
    createStyles({
      Img: {
        height: '7em',
        margin: '2.5em',
      },
      title: {
        color: 'white',
        textAlign: 'center',
        fontWeight: 'bold',
        fontSize: '1.5em'
      },
    })
  );
  const classes = useStyles()
  return (
    <React.Fragment>
    <Grid
      container
      spacing={1}
      direction="row"
      justifyContent="center"
      alignItems="center"
      alignContent="center"
      wrap="wrap"
    >
      <Grid item xs={12} sm={6} md={6} lg={3} style={{textAlign: 'center'}}  >
        <Link to={"/Set-full"}>
          <img
            style={{
              backgroundColor: "black",
              borderRadius: "20px",
              padding: "1.5rem",
              height: "4rem",
              boxShadow: '10px 10px 5px 0px rgba(0,0,0,0.75)',
            }}
            src={set}
            className={classes.Img}
            alt="SET Logo"
          />
        </Link>
      </Grid>
      <Grid item xs={12} sm={6} md={6} lg={3} style={{textAlign: 'center'}}>
        <Link style={{ textDecoration: "none"}} to={"/Idk-full"}>
          <img
            style={{
              backgroundColor: "black",
              borderRadius: "20px",
              padding: "1.25rem",
              paddingLeft: "2rem",
              height: "7.5rem",
              boxShadow: '10px 10px 5px 0px rgba(0,0,0,0.75)',
            }}
            src={idk}
            className={classes.Img}
            alt="IDKThat's Perfect Logo"
          />
        </Link>
      </Grid>
      <Grid item xs={12} sm={6} md={6} lg={3} style={{textAlign: 'center'}} >
        <Link
    
          to="TRA-Electric"
        >
         
            <img
              style={{
                backgroundColor: "white",
                borderRadius: "20px",
                height: "7.5rem",
                //boxShadow 
                boxShadow: '10px 10px 5px 0px rgba(0,0,0,0.75)',
                padding: "1.5rem ",
              }}
              src={tra}
              className={classes.Img}
              alt="TRA Logo"
            />
         
        </Link>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}  >
        <Link style={{ textDecoration: "none", marginBottom: "5em",  }} to={"/ExtractTextTool"}>
          <Typography style={{
              backgroundColor: "black",
              borderRadius: "20px",
              padding: "1.25rem",
              paddingLeft: "2rem",
              boxShadow: '10px 10px 5px 0px rgba(0,0,0,0.75)',
              maxWidth: 'fit-content',
              margin: 'auto',
            }} className={classes.title}>Text Extraction Tool</Typography>
        </Link>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}  >
        <Link style={{ textDecoration: "none", marginBottom: "5em",  }} to={"/Skills&Experience"}>
          <Typography style={{
              backgroundColor: "black",
              borderRadius: "20px",
              padding: "1.25rem",
              paddingLeft: "2rem",
              boxShadow: '10px 10px 5px 0px rgba(0,0,0,0.75)',
              maxWidth: 'fit-content',
              margin: 'auto',
            }} className={classes.title}>Skills and Experience</Typography>
        </Link>
      </Grid>
    </Grid>
  </React.Fragment>



  );
}

export default Landing;
