// App.js
import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import NavBar from "./NavBar";
import IDKFULL from "../routes/idk/components/App";
import Landing from "../routes/Landing";
import SetFull from "../routes/set/components/App";
import Resume from "../routes/Resume";
import OCR from "../routes/ExtractTextTool";
import { Tra } from "../routes/tra/Tra";

function App() {
  return (
    <Router>
      <NavBar />
      <Switch>
        <Route path="/ExtractTextTool">
          <OCR />
        </Route>
        <Route path="/Skills&Experience">
          <Resume />
        </Route>
        <Route path="/TRA-Electric">
          <Tra />
        </Route>
        <Route path="/Set-Full">
          <SetFull />
        </Route>
        <Route path="/Idk-Full">
          <IDKFULL />
        </Route>
        <Route exact path="/">
          <Landing />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
