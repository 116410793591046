import axios from 'axios';

let URL = 'https://us-central1-business-solutions-4568c.cloudfunctions.net/summarizeText';

// if (process.env.REACT_APP_ENV === "local") {
//   URL = "http://localhost:5001/business-solutions-4568c/us-central1/summarizeText";
// } 
// else {
//   URL = 'https://us-central1-business-solutions-4568c.cloudfunctions.net/summarizeText';
// }

export const summarizeText = async (text: string): Promise<string> => {
  try {

    const response = await axios.post( URL, {
      messages: [
        {
          role: 'system',
          content: "Please perform a grammar and spell check on the text. Use Markdown formatting to enhance the readability and presentation of the content. Organize the key points in a numbered list, centered on the page. After the list, add a paragraph labeled ‘Summary’ that encapsulates the main points of the text. Make sure to bold any key terms for emphasis return output as markdown.",
        },
        {
          role: 'user',
          content: text,
        },
      ],
    });

    return response.data.choices[0].message.content;
  } catch (error) {
    console.error('Error summarizing text:', error);
    throw error;
  }
};
