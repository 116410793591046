import React from 'react';
import { makeStyles, Modal, Backdrop, Fade, Typography, Button, Card } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        // backgroundColor: theme.palette.background.paper,
        // border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        maxWidth: '100%',
        maxHeight: '100%',
        textAlign: 'center',
        overflowY: 'auto', // Enable vertical scrolling
    },
}));

interface ProjectInfoModalProps {
    open: boolean;
    handleClose: () => void;
}

const ProjectInfoModal: React.FC<ProjectInfoModalProps> = ({ open, handleClose }) => {
    const classes = useStyles();

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>
                <Card className={classes.paper} >
                    <Typography variant="h4" id="transition-modal-title">Project Information</Typography>
                    <Typography variant="body1" id="transition-modal-description" style={{marginBottom: '1em'}}>
                        This project is a React application that allows users to extract text from pasted images
                        using Tesseract.js and then summarize the extracted text using OpenAI's GPT-3.5-turbo model.
                       
                    </Typography>
                    <Typography variant="body2" paragraph>
                        <b>Backend Architecture:</b>


                        <b>Utilized Firebase Functions:</b>

                        <p><b>Proxy Setup:</b> Firebase Functions are used to establish a secure proxy for handling API calls to the OpenAI GPT API. This approach enhances security by preventing the exposure of API keys and sensitive data in the client-side code.</p>
                        <p><b>Serverless Execution:</b> By leveraging Firebase's serverless environment, the functions scale automatically with demand, ensuring reliable and efficient processing without the need for manual server management.</p>
                        <p><b>API Handling:</b> The proxy functions are responsible for receiving API requests from the frontend, adding necessary authentication headers, and forwarding the requests to the GPT API. The functions also process the responses before sending them back to the client, enabling centralized error handling and logging.</p>



                        <b>Cloud Infrastructure:</b>


                        <b>Hosting with Firebase Hosting:</b>

                        <p><b>Static File Serving:</b> The frontend React application is deployed using Firebase Hosting, which provides a fast, secure, and globally distributed content delivery network (CDN) for serving static assets.</p>
                        <p><b>Single-Page Application (SPA) Configuration:</b> Firebase Hosting is configured to serve the index.html file for all routes, ensuring smooth navigation in the React application.</p>



                        <b>Environment Management:</b>

                        <p><b>Secure Configuration:</b> Environment variables and API keys are securely managed using Firebase Functions' environment configuration. This ensures sensitive information is never exposed in the client-side code or version control.</p>
                        <p><b>Development and Production Environments:</b> Separate configurations for local development and production environments allow seamless transitions between stages, ensuring that the application behaves consistently across different environments.</p>



                        <b>Advantages of this Setup:</b>

                        <p><b>Enhanced Security:</b> By handling API calls server-side, sensitive information such as API keys are kept secure, reducing the risk of exposure and misuse.</p>
                        <p><b>Scalability:</b> Firebase's serverless architecture ensures that the backend can scale effortlessly to handle varying loads, providing a reliable experience even during traffic spikes.</p>
                        <p><b>Simplified Maintenance:</b> The serverless nature of Firebase Functions means there's no need to manage infrastructure, allowing developers to focus on building features rather than maintaining servers.</p>
                        <p><b>Improved Performance:</b> Firebase Hosting’s CDN ensures that the React application is delivered quickly to users around the globe, enhancing the user experience.</p>

                    </Typography>
                    <Typography variant="body2">
                        Key Features:

                        <p>Extract text from pasted images</p>
                        <p>Summarize extracted text using OpenAI</p>
                        <p>Responsive design with Material-UI</p>

                    </Typography>
                    <Button variant="contained" color="primary" onClick={handleClose}>
                        Close
                    </Button>
                </Card>
            </Fade>
        </Modal>
    );
};

export default ProjectInfoModal;
