import React from "react";
import { Link, BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "../App.css";
import TwitterIcon from "@material-ui/icons/Twitter";
import AppleIcon from "@material-ui/icons/Apple";
import FacebookIcon from "@material-ui/icons/Facebook";
import idkMain from "../resources/images/idkLogoLarge.png";
import YouTubeIcon from "@material-ui/icons/YouTube";
import { createStyles, Grid, makeStyles, Typography, useMediaQuery, } from "@material-ui/core";
import Footer from "../components/Footer";
import { Icon } from "@iconify/react";

function Resume() {

  const isLargeScreen = useMediaQuery("(min-width:1280px)");
  const isMediumScreen = useMediaQuery("(max-width:1280px)");
  const isSmallScreen = useMediaQuery("(max-width:600px)");
  let title = {}
  let mainDiv = {};
  let subtitle = {};
  let summary = {};
  let body = {};
  let subBody = {};
  let center = {};
  let centerTitle = {}

  if (isLargeScreen) {
    centerTitle = {
      color: "#373f43",
      textAlign: "left",
      fontWeight: "bold",
      textDecoration: "underline",
      margin: "auto",
      width: "95%",
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center'
    };
    center = {
      margin: "auto",
      width: "95%",
      textAlign: "center",
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
    };
    title = {
      color: "#373f43",
      textAlign: "left",
      fontWeight: "bold",
      margin: ".5em",
      textDecoration: "underline"
    };
    mainDiv = {
      margin: "auto",
      width: "95%",
      textAlign: "center",
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'left',
    };
    subtitle = {
      fontSize: "1em",
      textAlign: "center",
      fontWeight: "bold",
      margin: ".5em",
      color: "#373f43",
    };
    summary = {
      fontSize: "1em",
      textAlign: "left",
      fontWeight: "bold",
      margin: ".5em",
      color: "#373f43",
    };
    body = {
      fontSize: ".95em",
      textAlign: "left",
      margin: ".5em",
      color: "#373f43",
    };
    subBody = {
      fontSize: ".95em",
      textAlign: "left",
      margin: ".5em",
      color: "#373f43",
      paddingLeft: '2em'
    }
  }
  if (isMediumScreen) {
    centerTitle = {
      color: "#373f43",
      textAlign: "left",
      fontWeight: "bold",
      textDecoration: "underline",
      margin: "auto",
      width: "95%",
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center'
    };
    center = {
      margin: "auto",
      width: "95%",
      textAlign: "center",
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
    };
    title = {
      color: "#373f43",
      textAlign: "center",
      fontWeight: "bold",
      margin: ".5em",
      textDecoration: "underline"
    };
    mainDiv = {
      margin: "auto",
      width: "95%",
      textAlign: "center",
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
    };
    subtitle = {
      fontSize: "1em",
      textAlign: "center",
      fontWeight: "bold",
      margin: ".5em",
      color: "#373f43",
    };
    summary = {
      fontSize: "1em",
      textAlign: "left",
      fontWeight: "bold",
      margin: ".5em",
      color: "#373f43",
    };
    body = {
      fontSize: ".95em",
      textAlign: "left",
      margin: ".5em",
      color: "#373f43",
    };
    subBody = {
      fontSize: ".95em",
      textAlign: "left",
      margin: ".5em",
      color: "#373f43",
      paddingLeft: '2em'
    }
  }
  if (isSmallScreen) {
    centerTitle = {
      color: "#373f43",
      textAlign: "left",
      fontWeight: "bold",
      textDecoration: "underline",
      margin: "auto",
      width: "95%",
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center'
    };
    center = {
      margin: "auto",
      width: "95%",
      textAlign: "center",
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
    };
    title = {
      color: "#373f43",
      textAlign: "center",
      fontWeight: "bold",
      margin: ".5em",
      textDecoration: "underline"
    };
    mainDiv = {
      margin: "auto",
      width: "100%",
      textAlign: "center",
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
    };
    subtitle = {
      fontSize: "1em",
      textAlign: "center",
      fontWeight: "bold",
      margin: ".5em",
      color: "#373f43",
    };
    summary = {
      fontSize: "1em",
      textAlign: "center",
      fontWeight: "bold",
      margin: ".5em",
      color: "#373f43",
    };
    body = {
      fontSize: ".95em",
      textAlign: "left",
      margin: ".5em",
      color: "#373f43",
    };
    subBody = {
      fontSize: ".95em",
      textAlign: "left",
      margin: ".5em",
      color: "#373f43",
      paddingLeft: '2em'
    }
  }


  const useStyles = makeStyles(() =>
    createStyles({
      mainDiv: mainDiv,
      title: title,
      subTitle: subtitle,
      summary: summary,
      body: body,
      subBody: subBody,
      center: center,
      centerTitle: centerTitle
    })
  );


  const classes = useStyles();
  return (
    <React.Fragment>
      <Grid
        container
        spacing={1}
        direction="row"
        // justifyContent="center"
        // alignItems="center"
        // alignContent="center"
        className={classes.mainDiv}

      >
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}  >
          <Typography variant="h4" className={classes.centerTitle}>
            Victor Jaimes-Puente
          </Typography></Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}  >
          <Typography variant="h5" className={classes.subTitle} >
            victor.jaimes.puente@gmail.com | 737-224-3665
            <br />
            Canyon Lake, Texas, United States
          </Typography>
        </Grid>
        <Typography className={classes.summary}>
          Accomplished Full-Stack Developer with extensive expertise in designing and implementing scalable web solutions using React and Node.js.
          {/* <br></br> */}
          Proficient in a range of cloud technologies across AWS, Azure, and GCP, with a proven track record of deploying robust cloud infrastructure to support complex applications.
          {/* <br></br> */}
          Highly adept at collaborating with cross-functional teams to deliver projects that meet demanding requirements, demonstrating a strong commitment to technical excellence and innovation in the development landscape.
          {/* <br></br> */}

        </Typography>
        <br></br>

        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}  >
          <Typography variant="h5" className={classes.centerTitle}>
            Technical Skills
          </Typography>
          <Typography >


            <ul style={{ listStyleType: 'none', marginLeft: '-2.8em' }}>
              <strong>Full-Stack Development</strong>
              <li>React (Frontend Development)</li>
              <li>Node.js Typescript (Backend Development)</li>
              <li>Java (Backend Development)</li>
            </ul>

            <ul style={{ listStyleType: 'none', marginLeft: '-2.8em' }} >
              <strong>Cloud Infrastructure Management</strong>
              <li>Azure, AWS, GCP </li>
              <li>Azure DevOps (Continuous Integration/Deployment)</li>
              <li>Docker (Containerization)</li>
              <li>Kubernetes (Container Orchestration)</li>

            </ul>

            <ul style={{ listStyleType: 'none', marginLeft: '-2.8em' }} >
              <strong>Agile Development Practices</strong>

              <li>Pair Programming</li>
              <li>Mob Programming</li>
              <li>Retrospectives</li>
              <li>User Story Mapping</li>
            </ul>
          </Typography>
          <br></br>
        </Grid>
        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}   >
          <Typography variant="h5" className={classes.centerTitle}>
            Soft Skills
          </Typography>
          <Typography>

            <ul style={{ listStyleType: 'none' }}>
              <strong>Interpersonal Skills</strong>
              <li>Effective Communication</li>
              <li>Team Collaboration</li>
              <li>Conflict Resolution</li>
              <li>Client Engagement</li>
              <li>Problem Solving</li>
              <li>Critical Thinking</li>
            </ul>

            <ul style={{ listStyleType: 'none' }} >
              <strong>Project Management and Execution:</strong>
              <li>Project Management</li>
              <li>Agile Methodologies</li>
              <li>Time Management</li>
              <li>Adaptability</li>
            </ul>

            <ul style={{ listStyleType: 'none' }} >
              <strong>Personal Skills:</strong>
              <li>Attention to Detail</li>
              <li>Stress Management</li>
            </ul>

          </Typography>
        </Grid>

        <br></br>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}  >
          <Typography variant="h5" className={classes.title}>
            Experience
          </Typography>
        </Grid>

        <Typography variant="h5" className={classes.subTitle}>
          Full-Stack Developer May 2023 - Present SynergenX, Remote
        </Typography>
        <Typography className={classes.body}>
          •	Provided technical assistance within the current technology environment by resolving support tickets and ensuring smooth operations using Azure DevOps.
          <br></br>
          •	Managed and maintained cloud solutions on both AWS and Azure platforms.
          <br></br>
          •	Developed and deployed a serverless solution using Azure Functions and Node.js with Typescript, which automated user provisioning from Microsoft Entra ID to a third-party training platform for new hires.
          <br></br>
          •	Collaborated with stakeholders, developers, and business analysts to design program logic that automated the processing of thousands of daily medical transactions with a vast number of variables and conditions which impacted the logic flow.
          <br></br>
          •	Collaborated with a third-party development team to define product requirements, review and approve technical specifications, and develop User Acceptance Testing (UAT) documentation.
          <br></br>
          •	Participated in technical discussions and contributed to resolving technical issues involving third-party development teams and vendors.
          <br></br>


        </Typography>
        <br></br>
        <Typography variant="h5" className={classes.subTitle}>
          Full-Stack Developer Jul 2022 - May 2023 , Remote
        </Typography>
        <Typography className={classes.body}>
          •	Developed, tested, and deployed robust full-stack web applications, utilizing a diverse array of technologies including React for front-end, Node.js for back-end development.
          <br />
          •	Collaborated closely with a multi-functional development team to ensure cohesive project outcomes.
          <br />
          •	Played a pivotal role in code reviews, significantly enhancing code quality and system stability.
          <br />
          •	Proficiently managed and optimized cloud resources using Amazon Web Services (AWS), incorporating services such as S3, CloudFront, Route 53, API Gateway, and Lambda, to ensure efficient application performance and scalability.

        </Typography>
        <br></br>
        <Typography variant="h5" className={classes.subTitle}>
          Full-Stack Developer May 2021 - Aug 2021 School Event Tickets, San Antonio, US
        </Typography>
        <Typography className={classes.body}>
          •	Developed a dynamic web application using React for frontend and Node.js for backend integration, ensuring high performance and exceptional user responsiveness to compete with top industry competitors.
          <br />
          •	Collaborated effectively with steak holders and design teams within an Agile framework to align development with client specifications and performance criteria.
          <br />
          •	Utilized a range of Google Cloud Platform (GCP) services, including Cloud Storage, Cloud CDN, Cloud DNS, Cloud EndPoints, and Cloud Functions, to architect and manage a robust cloud infrastructure, enhancing scalability and reliability.
          <br />
          •	The website was designed to compete with a leading competitor in the industry and performed so well that the competitor purchased it from my employer.
          <br />

        </Typography>
        <br></br>
        <Typography variant="h5" className={classes.subTitle}>
          Full-Stack Developer / DevOps Jul 2020 - Feb 2021 Appddiction Studio, San Antonio, US
        </Typography>
        <Typography className={classes.body}>
          •	Developed a full-stack web platform tailored for large-scale clients, utilizing React and Java to deliver exceptional functionality and user experience.
          <br />
          •	Actively engaged with clients daily, ensuring comprehensive alignment with their needs through each phase of the Agile development process and achieving their full satisfaction with the final product.
          <br />
          •	Played a key role in the development, testing, deployment, and security of a large-scale cloud application, employing advanced tools such as AWS, Docker, Kubernetes, and GitLab.
          <br /> My contributions helped automate and secure the deployment process, enabling effective scaling and robust infrastructure management.


        </Typography>
        <br></br>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={classes.mainDiv} >
          <Typography variant="h5" className={classes.subTitle} >
            Team Leader Jan 2013 - Apr 2016 United States Army
          </Typography>
        </Grid>
        <Typography className={classes.body}>
          • Led and trained multiple groups of up to thirty personnel in over seventy-two job-oriented classes during a twenty-four-month period, maintaining a high standard of instruction to enhance their professional development and job performance.
          <br />
          • Supervised and managed up to ten personnel in over fifty high-stress and highly adverse operations, resulting in zero percent failure or loss and ensuring the safety and success of all team members.
          <br />
          • Provided comprehensive leadership and support to soldiers, including overseeing their finances, identifying their learning styles,
          monitoring their psychological and physical well-being, and developing tailored training plans to enhance their skills and abilities.
          <br />
          • Maintained a high level of professionalism, communication, and collaboration with soldiers, peers, and superiors to achieve the mission and foster a positive team culture.
        </Typography>
        <br></br>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}  >
          <Typography variant="h5" className={classes.title}>
            Education / Certifications
          </Typography>
        </Grid>
        <Typography className={classes.body}>
          •	Microsoft Certified: Azure Fundamentals – Microsoft
          <br />
          •	AWS Certified Developer - Amazon Web Services (AWS)
          <br />
          •	ACI Learning
          <Typography variant="h5" className={classes.subBody} >
            Information Security Analyst (ISA), Cyber/Electronic Operations and Warfare Feb 2022 - Mar 2022
          </Typography>
          <br />
          •	Codeup
          <Typography variant="h5" className={classes.subBody} >
            Software Development
            <br />
            2019 - 2020
            <br />
            Successfully completed a full-time, 20-week full-stack Java development program at Codeup, where I dedicated over 8 hours daily to hands-on, project-based learning. The course delivered more than 670 hours of expert instruction, focusing on building robust full-stack applications through extensive coding and real-world projects.

          </Typography>
          <br />

        </Typography>
        <br></br>
        <Typography variant="h5" className={classes.title}>
          LinkedIn
        </Typography>

        <a href="https://linkedin.com/in/victor-jaimes-puente" target='blank'><Typography className={classes.body} style={{ marginTop: '1.25em' }}>
          linkedin.com/in/victor-jaimes-puente
        </Typography></a>
      </Grid>
      {/* <Footer /> */}
    </React.Fragment>
  );
}

export default Resume;

