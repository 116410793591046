import ResponsiveEmbed from "react-responsive-embed";

export const Tra = () => {
    return (
        <>
            {/* <div style={{position : 'relative', width: "100%", height: '100%',  }}>
                <ResponsiveEmbed 
                    title="External Content"
                    src="https://tra-electric.com" // Replace this with the external link you want to open
                    style={{
                        border: "none",
                        width: "100%",
                        height: "100%",
                        overscrollBehavior: "contain",
                    }}
                />
            </div> */}
            <div style={{ width: "100%", height: "100vh",  }}>
                <iframe
                    title="External Content"
                    src="https://tra-electric.com" // Replace this with the external link you want to open
                    style={{
                        border: "none",
                        width: "100%",
                        height: "100%",
                    }}
                ></iframe>
            </div>
        </>
    )
}