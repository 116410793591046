import AppBar from "@material-ui/core/AppBar";
import Grid from "@material-ui/core/Grid";
import blackLogo from "../resources/images/DapperNoBG.png";
import { Link, BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import HomeIcon from '@material-ui/icons/Home';


// Function for Navbar
export default function NavBar() {
  const useStyles = makeStyles(() =>
    createStyles({
      navBar: {
        backgroundColor: "black",
        flexGrow: 1,
        position: "relative",
      },
      navImg: {
        height: '2rem',
        color: 'white',
      },
      gridContainer: {
        // marginTop: '1em',
        display: "flex",
        justifyContent: "start",
      },
      navGridItem: {},
    })
  );
  const classes = useStyles();
  return  <>
  <AppBar position="sticky" className={classes.navBar}>
    <Grid className={classes.gridContainer}>
      {/* First Button */}
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        className={classes.navGridItem}
      >
        <Link to={"/"}>
          <HomeIcon
            
            className={classes.navImg}
           
          />
        </Link>
        {/* /* Second Button */}
      </Grid>
    </Grid>
  </AppBar>
</>
  
}
